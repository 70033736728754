import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { slideInAnimation } from '@app/animations';
import {formatDate} from '@angular/common';

import { AuthenticationService } from '@services/authentication.service';
import { User, UserRole } from '@models/index';
import { Meta } from '@angular/platform-browser';

// https://aglowiditsolutions.com/blog/angular-seo/
@Component({ 
    selector: 'app-root', 
    templateUrl: 'app.component.html',
    animations: [ slideInAnimation ]
})
export class AppComponent implements OnInit{
    user: User = new User;

    constructor(@Inject(LOCALE_ID) private locale: string, private authenticationService: AuthenticationService, private meta: Meta) {
        this.authenticationService?.user?.subscribe(x => this.user = x);
    }
    ngOnInit(): void {
        const dt = new Date();
        this.meta.addTags([
            {name: 'keywords', content: 'sokker, underbukser,køb sokker, køb underbukser, underbukser mænd, underbukser drenge, sokker mænd, sokker drenge, farvede sokker, boxershorts, hvide underbukser, gaver til manden der har alt, gaveæske, gaver til mænd, gaver til børn, gaver til drenge, gave til mand, gave til dreng, kvalitets sokker, kvalitets underbukser, billige sokker, billige underbukser, tilbud sokker, tilbud underbukser'},
            {name: 'robots', content: 'index. follow'},
            {name: 'author', content: 'socksNjocks.dk'},
            {name: 'viewport', content: 'width=device-width, initial-scale=1'},
            {name: 'date', content: formatDate(Date.now(), 'yyyy-MM-dd', this.locale), scheme: 'yyyy-MM-dd'},
            {charset: 'UTF-8'}
        ])
    }

    get isAdmin() {
        return this.user && this.user.role === UserRole.Admin;
    }

    logout() {
        this.authenticationService.logout();
    }

    getAnimationData(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
      }
}