    <div class="card d-flex mx-auto h-100" *ngIf="product">
        <a [routerLink]="link"><img *ngIf="image != undefined" src="{{image}}" alt="" class="card-img-top pointer"></a>
        <div class="card-body bg-light text-center">
            <div class="mb-2">
                <p class="mb-1 font-weight-semibold"> <a [routerLink]="link">{{product.name}}</a> </p> 
                <p *ngIf="showDesciption" class="mb-1"><small>{{product.shortDescription}}</small></p>
            </div>
            <h3 class="mb-2 font-weight-semibold">{{product.price}} kr.</h3>
            <div class="mb-1">
              <div class="btn-group btn-group-toggle w-100" role="group">
                <label [ngClass]="size.id === selectedSize ? 'btn btn-outline-success btn-sm' : 'btn btn-secondary btn-sm'" *ngFor="let size of product.sizes">
                  <input type="radio" (click)="enableCart(size.id)" [value]="size.id">{{size.name}}
                </label>
              </div>
            </div>
            <button [ngClass]="btnClass" [disabled]="cartDisabled" (click)="addToCart()"><i class="fal fa-cart-plus"></i>Læg i kurv</button>
        </div>
    </div>