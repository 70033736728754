<div class="container-fluid">
    <ngb-carousel #itemslider class="item-slider" *ngIf="productSlides.length != 0" [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let productSlide of productSlides; index as i">
                <div class="row swipe-y" (swipeleft)="onSwipe(itemslider, $event.type)" (swiperight)="onSwipe(itemslider, $event.type)">
                    <div *ngFor="let product of productSlide.products" [ngClass]="productSlide.class">
                        <app-product-tile [product]="product" [showDesciption]="false"></app-product-tile>
                    </div>
                </div>
              </ng-template>
    </ngb-carousel>
</div>