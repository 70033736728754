<div class="container-fluid bg-dark text-white navbar-static-bottom ">
  <footer class="py-5">
    <div class="row text-center">
      <div class="col-4">
        <i class="fal fa-shipping-fast fa-2x"></i>
        <h5>Fragt</h5>
        <p class="small">Vi leverer til de bedste fragtpriser i hele Danmark.</p>
      </div>
      <div class="col-4">
        <i class="fal fa-user-chart fa-2x"></i>
        <h5>Returportal</h5>
        <p class="small">Nemt og enkelt via med billig returfragt.</p>
      </div>
      <div class="col-4">
        <i class="fal fa-smile fa-2x"></i>
        <h5>Tilfredshed</h5>
        <p class="small">Vi gør næsten alt for at have tilfredse kunder.</p>
      </div>
    </div>
    <div class="row text-center">
      <div class="col-md-4 col-6">
        <h5><i class="fal fa-info-circle"></i>Information</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a routerLink="/sitemap" class="nav-link p-0 text-muted">Sitemap</a></li>
          <li class="nav-item mb-2"><a routerLink="/fragt-og-forsendelse" class="nav-link p-0 text-muted">Fragt og forsendelse</a></li>
          <li class="nav-item mb-2"><a routerLink="/privatliv" class="nav-link p-0 text-muted">Privatliv</a></li>
          <li class="nav-item mb-2"><a routerLink="/handelsbetingelser" class="nav-link p-0 text-muted">Handelsbetingelser</a></li>
          <li class="nav-item mb-2"><a routerLink="/om-os" class="nav-link p-0 text-muted">Om os</a></li>
          <li class="nav-item mb-2"><a routerLink="/kontakt" class="nav-link p-0 text-muted">Kontakt os</a></li>
        </ul>
      </div>

      <div class="col-md-4 col-6">
        <h5><i class="fal fa-user"></i>Mine sider</h5>
        <ul class="nav flex-column">
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Konto</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Ordrer</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Adresser</a></li>
          <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-muted">Varekurv</a></li>
        </ul>
      </div>

      <div class="col-md-4 col-sm-12">
        <form>
          <h5>Tilmeld dig vores nyhedsbrev</h5>
          <p>Få nyheder og gode tilbud.</p>
          <div class="d-flex w-100 gap-2">
            <label for="newsletter1" class="visually-hidden">Email adresse</label>
            <input id="newsletter1" type="text" class="form-control" placeholder="Email adresse">
            <button class="btn btn-primary" type="button">Tilmeld</button>
          </div>
        </form>
      </div>
    </div>

    <div class="d-flex justify-content-center my-2">
      <p class="text-center text-muted">&copy; 2021 socksNjocks. Alle rettigheder forbeholdt. Alle priser er inkl. moms.</p>
      <ul class="list-unstyled d-flex">
        <li class="ms-3"><a class="link-light" href="#"><i class="fab fa-twitter"></i></a></li>
        <li class="ms-3"><a class="link-light" href="#"><i class="fab fa-instagram-square"></i></a></li>
        <li class="ms-3"><a class="link-light" href="#"><i class="fab fa-facebook"></i></a></li>
      </ul>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <a href="http://www.dibs.dk" target="_blank"><img src="assets/payment/nets.png" class="me-2" width="60" /></a>
      <img src="assets//payment/card_Dankort_2019.png" class="me-2" />
      <img src="assets//payment/card_Visa_53x33.png" class="me-2"/>
      <img src="assets//payment/card_Mastercard_53x33.png" class="me-2" />
      <img src="assets/payment/MobilePay_55x33.jpg" />
  </div>
  </footer>
</div>
