import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiModule } from '.';
import { Cart } from '@models/cart'
import { AnonymousSubject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private items: ApiModule.ProductDto[];
  private cartSubject = new Subject<Cart>();
  cart = this.cartSubject.asObservable();
  constructor() { 
    this.items = JSON.parse(localStorage.getItem('items') ||'[]');
    this.cartSubject.next(<Cart>{initialized: true, items:  this.items});    
  }

  init(){
    this.items = JSON.parse(localStorage.getItem('items') ||'[]');
    this.cartSubject.next(<Cart>{initialized: true, items:  this.items});  
  }

  addToCart(item: ApiModule.ProductDto) {
    let exists = this.items.find(i => { return item.id == i.id && item.size == i.size });
    if(exists != undefined){
      exists.quantity++; 
    }
    else{
      this.items.push(item);
    }
    
    this.syncItems();
  }

  get count(){
    let count = 0;
    for(let i = 0; i < this.items.length; i++){
      count += this.items[i].quantity
    }
    return count;
  }

  increaseQuantity(item: ApiModule.ProductDto){
    let exists = this.items.find(i => { return item.id == i.id && item.size == i.size });
    if(exists != undefined){
      exists.quantity++;
    }
    this.syncItems();
  }

  decreaseQuantity(item: ApiModule.ProductDto){
    let exists = this.items.find(i => { return item.id == i.id && item.size == i.size });
    if(exists != undefined){
      exists.quantity--;
    }
    this.syncItems();
  }

  setQuantity(item: ApiModule.ProductDto, quantity: number){
    let exists = this.items.find(i => { return item.id == i.id && item.size == i.size });
    if(exists != undefined){
      exists.quantity = quantity;
    }
    this.syncItems();
  }

  removeFromCart(item: ApiModule.ProductDto) {
    const index = this.items.indexOf(item);
    this.items.splice(index,1);
    this.syncItems();
  }

  getItems() {
    return this.items;
  }

  clearCart() {
    this.items = [];
    this.syncItems();
    return this.items;
  }

  syncItems(){
    this.cartSubject.next(<Cart>{initialized: true , items:  this.items});
    localStorage.setItem('items',JSON.stringify(this.items));
  }

  totalWithVat(items: ApiModule.ProductDto[], shippingPrice: number):number{
    let amount = 0;
    for(let i = 0; i < items.length; i++){
      amount += items[i].price * items[i].quantity;
    }
    amount += shippingPrice;
    return amount;
  }

  totalWithVatNoShipping(items: ApiModule.ProductDto[]):number{
    let amount = 0;
    for(let i = 0; i < items.length; i++){
      amount += items[i].price * items[i].quantity;
    }
    return amount;
  }

  totalExVat(items: ApiModule.ProductDto[]):number{
    let amount = 0.00;
    for(let i = 0; i < items.length; i++){
      amount += (items[i].price * items[i].quantity) * 0.8;
    }
    return amount;
  }

  totalVat(items: ApiModule.ProductDto[]):number{
    return this.totalWithVatNoShipping(items) - this.totalExVat(items);
  }
}
