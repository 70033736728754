import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent implements OnInit {
  constructor(private meta: Meta, private title: Title){
  }
  ngOnInit(): void {
    this.title.setTitle('socksNjocks.dk - Forside');
    this.meta.updateTag({name: 'description', content: 'køb sokker og underbukser hos socksNjocks.dk'})
  }
}
