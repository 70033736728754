<body>
  <!-- <div class="vacation-overlay">
    <div class="vacation-inner">
      <h1>Vi holder sommerferie <i class="fal fa-smile"></i></h1>
      <p>Shoppen er lukket til 1. september 2023</p>
      <p>Du ønskes en rigtig god sommer!</p>
    </div>
  </div> -->
  <app-nav-menu></app-nav-menu>
  <main class="main">
    <div [@routeAnimation]="getAnimationData(routerOutlet)">
      <router-outlet #routerOutlet="outlet"></router-outlet>
    </div>
    <app-footer></app-footer>
  </main>
</body>
