<div class="container">
    <div class="row" *ngIf="changed === SubmitState.none">
        <div class="col-md-6 offset-md-3 mt-5">
            <div class="card">
                <h4 class="card-header">Skift password</h4>
                <div class="card-body">
                    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
                        <div class="form-group mt-2">
                            <label class="visually-hidden" for="inlineFormInputGroupPassword">Password</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-key me-0"></i></div>
                                <input type="password" class="form-control" id="inlineFormInputGroupPassword" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                    <div *ngIf="f['password'].errors['required']">Password skal udfyldes</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="visually-hidden" for="inlineFormInputGroupPassword">Gentag Password</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-key me-0"></i></div>
                                <input type="password" class="form-control" id="inlineFormInputGroupRepeatPassword" placeholder="Gentag Password" formControlName="repeatpassword" [ngClass]="{ 'is-invalid': submitted && f['repeatpassword'].errors }">
                                <div *ngIf="submitted && f['repeatpassword'].errors" class="invalid-feedback">
                                    <div *ngIf="f['repeatpassword'].errors['required']">Password skal udfyldes</div>
                                    <div *ngIf="f['repeatpassword'].errors['matching']">Password skal være ens</div>
                                </div>
                            </div>
                        </div>
                        <a class="btn btn-link float-start mt-2" [routerLink]="'/glemt-password'" >Glemt Password?</a>
                        <button [disabled]="loading" class="btn btn-primary float-end mt-4">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Skift password
                        </button>
                    </form>
                </div>
            </div>
        </div>   
    </div>
    <div class="row">
        <div class="col-12" *ngIf="changed ===  SubmitState.success">
            <h3>Dit password er ændret du kan nu logge ind med dit nye password</h3>
            <p>Vend tilbage til <a [routerLink]="'/log-ind'">Login siden</a></p>
        </div>
    </div>
    <div class="row">
        <div class="col-12" *ngIf="changed ===  SubmitState.failure">
            <h3>Dit password kunne ikke ændres, da der opstod en fejl.</h3>
            <p>Vend tilbage til <a [routerLink]="'/log-ind'">Login siden</a> og bed om at få gensendt et nyt link til nulstilling.</p>
        </div>
    </div>
</div>
         