import { Component, OnInit, Input,ViewChild, OnDestroy } from '@angular/core';
import { ApiModule } from '@services/api.service';
import { getWindow } from 'ssr-window';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-item-slider',
  templateUrl: './item-slider.component.html',
  styleUrls: ['./item-slider.component.scss']
})
export class ItemSliderComponent implements OnInit, OnDestroy {
  @Input() boxType: string = "sokker";
  @ViewChild('itemslider', {static : true}) carousel!: NgbCarousel;
  resizeObservable$!: Observable<Event>
  resizeSubscription$!: Subscription

  tileCount = 3;
  productSlides: {index: number, class: string, products: ApiModule.ProductDto[]}[] = [];
  products: ApiModule.ProductDto[] = [];
  boxTypes!: ApiModule.BoxType[];

  //Slider config
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  showNavigationArrows = true;
  showNavigationIndicators = false;

  constructor(
    private shopService: ApiModule.ShopService
    ) {
     }
  ngOnDestroy(): void {
    if(this.resizeSubscription$)
    this.resizeSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.tileCount  = this.calcTileCount();
    this.getBoxes();
    this.resizeObservable$ = fromEvent(getWindow(), 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.calcSlides();
    })
  }
  getBoxes(){
    this.boxTypes = this.boxType == 'underbukser' ? [ApiModule.BoxType.MagnetBoxUnderwear, ApiModule.BoxType.SuitcaseUnderwear] : [ApiModule.BoxType.MagnetBoxSocks, ApiModule.BoxType.SuitcaseSocks];
    this.shopService.getBoxes(this.boxTypes).subscribe(boxes=>{
      this.products = boxes;
      this.calcSlides();
    })
  }

  calcTileCount(){
    if(getWindow() === undefined) return 3;
    const w = getWindow().innerWidth;
    if(w < 992) { return 1; };
    return 3;
  }


  calcSlides(){
    this.productSlides = [];
    const itemsPerSlide = this.calcTileCount();
    const ngClass = 'col-' + Math.ceil(12/itemsPerSlide).toString();
    var totalItems = Math.ceil(this.products.length / itemsPerSlide);
      let next= this.iterator(this.products, itemsPerSlide);
      for (var i=0; i<totalItems; i++) {
        this.productSlides.push({
          index: i,
          class : ngClass,
          products: next()
        });
    }
  }

  iterator = function(a:ApiModule.ProductDto[], n:number) {
    var current = 0,
        l = a.length;
      return function() {
          let end = current + n;
          var part = a.slice(current,end);
          current =  end < l ? end : 0;
          return part;
      };
  };

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  onSwipe(carousel: NgbCarousel, action: string = this.SWIPE_ACTION.RIGHT){
    carousel.pause();
    if(action == this.SWIPE_ACTION.RIGHT){
      carousel.prev();
    }
    if(action == this.SWIPE_ACTION.LEFT){
      carousel.next();
    }
    carousel.cycle();
  }

  onSlide(slideEvent: NgbSlideEvent) {
    const tileCount = this.calcTileCount();
    if(tileCount != this.tileCount){
      this.tileCount = tileCount;
      this.calcSlides();
    }
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }
}
