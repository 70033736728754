<div class="container mt-4 justify-content-center">
    <div class="row">
        <div class="col-md-4 offset-md-4 col-sm-12">
            <img srcset="assets/404/404.svg" src="assets/404/404.png" class="img-fluid">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <h1 class="text-center">Beklager, det ser ud til at vi har sendt dig et forkert sted hen.</h1>
            <h2 class="text-center text-muted">Lad os hjælpe dig med at komme på rette vej igen!</h2>
            <p class="text-center">Se vores lækre <a href="#">sokker</a>, <a href="#">underbukser</a> - eller <a href="#">vend tilbage til forsiden</a>.</p>
        </div>
    </div>
</div>

