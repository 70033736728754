import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from '@services/cart.service';
import { ApiModule } from '@services/api.service';
import { Cart } from '@models/cart';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit, OnDestroy {
  items : ApiModule.ProductDto[];
  private cartSubscription! : Subscription;

  constructor(private cartService: CartService) { 
    this.items = this.cartService.getItems();
  }

  ngOnInit(): void {
    this.cartSubscription = this.cartService.cart.subscribe((cart : Cart) =>{
      this.items = cart.items;
    })
  }
  ngOnDestroy(): void {
    if(this.cartSubscription)
    this.cartSubscription.unsubscribe();
  }

  itemUrl(item: ApiModule.ProductDto){
    var slugPath = item.boxType === ApiModule.BoxType.MagnetBoxUnderwear || item.boxType === ApiModule.BoxType.SuitcaseUnderwear ?
      'underbukser' : 'sokker';
    return './shop/'+ slugPath + '/' + item.urlSlug;
  }
  itemImage(item: ApiModule.ProductDto){
    let path = 'images/box/' + item.id.toString() + '/thumbnail-200-';
    let image = item.image?.name;
    if(image){
      return path + image;
    }
    return ''
  }
}
