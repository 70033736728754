import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User, UserRole } from '../_models';

// import { ApiModule.UsersService, AuthenticateRequest } from 'src/app/_services/api.service'
import { ApiModule } from './api.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject: BehaviorSubject<User> = new BehaviorSubject<User>(new User);
    public user!: Observable<User>;

    constructor(
        private router: Router,
        private service: ApiModule.UsersService
    ) {
        let lUser = localStorage.getItem('user');
        if(lUser){
            this.userSubject.next(JSON.parse(lUser));
            this.user = this.userSubject.asObservable();
        }
    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username: string, password: string) {
        return this.service.authenticate({username, password})
        .pipe(map(res =>{
            let user: User = new User();
            user.id = res.id;
            user.firstName = res.firstName ?? '';
            user.lastName = res.lastName ?? '';
            user.role = res.role;
            user.token = res.token ?? '';
            user.username = res.username ?? '';
            localStorage.setItem('user', JSON.stringify(user));
            this.userSubject.next(user);
            return user;
        }))
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(new User);
        this.router.navigate(['/log-ind']);
    }
}