<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3 mt-5">
            <div class="card">
                <h4 class="card-header">Opret ny bruger</h4>
                <div class="card-body">
                    <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label class="visually-hidden" for="inlineFormInputGroupUsername">Email</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-at me-0"></i></div>
                                <input type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="Email" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }">
                                <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                                    <div *ngIf="f['username'].errors['required']">Email skal udfyldes</div>
                                    <div *ngIf="f['username'].errors['email']">Email skal være en valid email adress</div>
                                    <div *ngIf="f['username'].errors['maxlength']">Email kan højst være 255 karakterer lang</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="visually-hidden" for="inlineFormInputGroupPassword">Password</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-key me-0"></i></div>
                                <input type="password" class="form-control" id="inlineFormInputGroupPassword" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }">
                                <div *ngIf="submitted && f['password'].errors" class="invalid-feedback">
                                    <div *ngIf="f['password'].errors['required']">Password skal udfyldes</div>
                                    <div *ngIf="f['password'].errors['minlength']">Password skal være mindst 6 karakterer lang</div>
                                    <div *ngIf="f['password'].errors['maxlength']">Password kan højst være 40 karakterer lang</div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-2">
                            <label class="visually-hidden" for="inlineFormInputGroupRepeatPassword">Gentag Password</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-repeat me-0"></i></div>
                                <input type="password" class="form-control" id="inlineFormInputGroupRepeatPassword" placeholder="Gentag Password" formControlName="confirmPassword" [ngClass]="{ 'is-invalid': submitted && f['confirmPassword'].errors }">
                                <div *ngIf="submitted && f['confirmPassword'].errors" class="invalid-feedback">
                                    <div *ngIf="f['confirmPassword'].errors['required']">Password skal udfyldes</div>
                                    <div *ngIf="f['confirmPassword'].errors['matching']">Password skal være ens</div>
                                </div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-primary float-end mt-4">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Registrer
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </form>
                </div>
            </div>
        </div>   
    </div>
</div>
         