<div [class]="'mini-shopping-cart'">
    <div [class]="'cart-items'">
        <div [class]="'cart-item'" *ngFor="let item of items">
            <div [class]="'cart-picture'">
                <a [class]="'cart-image-link'" [routerLink]="itemUrl(item)" [title]="'Vis detaljer for' + item.name"> 
                    <img class="cart-image mx-auto d-block" alt="Billede af Klassisk box sokker i sort" [src]="itemImage(item)" [title]="'Vis detaljer for' + item.name"> 
                </a>
            </div>
            <div [class]="'cart-product'">
                <div [class]="'cart-name'">
                    <a [routerLink]="itemUrl(item)">{{ item.name }}</a>
                </div>
                <div [class]="'cart-attributes'">Størrelse: {{ item.size?.name }}</div>
                <div [class]="'cart-price'">Stk.pris: <span>{{ item.price }} kr.</span></div>
                <div [class]="'cart-quantity'">Antal: <span>{{ item.quantity }}</span></div>
            </div>
        </div>    
    </div>
    <div [class]="'cart-count'" *ngIf="items.length == 0">Du har ingen varer i din varekurv.</div>    
</div>
