<div class="container-fluid" *ngIf="testimonialSlides.length != 0">
    <ngb-carousel #testimonialslider class="item-slider" [interval]="5000" [pauseOnHover]="pauseOnHover" [pauseOnFocus]="pauseOnFocus" [showNavigationArrows]="showNavigationArrows" [showNavigationIndicators]="showNavigationIndicators" (slide)="onSlide($event)">
            <ng-template ngbSlide *ngFor="let testimonialSlide of testimonialSlides; index as i">
                <div class="row swipe-y" (swipeleft)="onSwipe(testimonialslider, $event.type)" (swiperight)="onSwipe(testimonialslider, $event.type)">
                    <div *ngFor="let item of testimonialSlide.testimonials" [ngClass]="testimonialSlide.class">                        
                        <div class="card d-flex mx-auto h-100">
                            <div class="card-image">
                              <img class="d-flex mx-auto" style="width: 150px;" src="assets/testimonials/{{item.avatar}}.png" srcset="assets/testimonials/{{item.avatar}}.svg">
                            </div>
                            <div class="card-body">
                              <h5 class="card-title text-center">{{item.title}}</h5>
                              <div [innerHTML]="item.content"></div>
                            </div>
                            <div class="card-footer text-end"> <span id="name">{{item.name}}</span></div>
                          </div>
                    </div>
                </div>
              </ng-template>
    </ngb-carousel>
</div>