<div class="loading-container">
    <div class="loading-inner">
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="circle"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <div class="shadow"></div>
        <span>Indlæser</span>
    </div>
</div>