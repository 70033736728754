import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { ApiModule } from '@services/api.service';

@Component({
  selector: 'app-user-forgot-password',
  templateUrl: './user-forgot-password.component.html',
  styleUrls: ['./user-forgot-password.component.scss']
})
export class UserForgotPasswordComponent implements OnInit {
  resetPasswordForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    error = '';
    done = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: ApiModule.UsersService
  ) { 
    this.resetPasswordForm  = this.formBuilder.group({
        username: ['', [Validators.required, Validators.email, Validators.maxLength(255)]]
    })
  }

  ngOnInit(): void {
    this.resetPasswordForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.maxLength(255)]]
  });
  }

      // convenience getter for easy access to form fields
      get f() { return this.resetPasswordForm.controls; }

      onSubmit() {
          this.submitted = true;
  
          // stop here if form is invalid
          if (this.resetPasswordForm.invalid) {
              return;
          }
  
          this.loading = true;
          this.userService.forgotPassword(this.f['username'].value).subscribe(res =>{
            this.done = true;
            this.loading = false;
          })
      }

}
