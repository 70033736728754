import { Component, OnInit, Input,ViewChild } from '@angular/core';
import { ApiModule } from '@services/api.service';
import { getWindow } from 'ssr-window';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent implements OnInit {
  @ViewChild('itemslider', {static : true}) carousel!: NgbCarousel;

tileCount = 3;
  testimonialSlides: {index: number, class: string, testimonials: ApiModule.Testimonial[]}[] = [];
  testimonials: ApiModule.Testimonial[] = [];

  //Slider config
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  showNavigationArrows = true;
  showNavigationIndicators = false;

  constructor(
    private shopService: ApiModule.ShopService
    ) {
     }

  ngOnInit(): void {
    this.tileCount  = this.calcTileCount();
    this.getTestimonials();
  }
  getTestimonials(){  
    this.shopService.getTestimonials().subscribe(testimonials =>{
      this.testimonials = testimonials;
      this.calcSlides();
    });
  }

  calcTileCount(){
    const w = getWindow().screen.width;
    if(w < 768){ return 1; };
    if(w < 992) { return 2; };
    return 3;
  }




  calcSlides(){
    this.testimonialSlides = [];
    const itemsPerSlide = this.calcTileCount();
    const ngClass = 'col-' + Math.ceil(12/itemsPerSlide).toString();
    var totalItems = Math.ceil(this.testimonials.length / itemsPerSlide);
      let next= this.iterator(this.testimonials, itemsPerSlide);
      for (var i=0; i<totalItems; i++) {
        this.testimonialSlides.push({
          index: i,
          class : ngClass,
          testimonials: next()
        });
    }
  }

  iterator = function(a:ApiModule.Testimonial[], n:number) {
    var current = 0,
        l = a.length;
      return function() {
          let end = current + n;
          var part = a.slice(current,end);
          current =  end < l ? end : 0;
          return part;
      };
  };

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };

  onSwipe(carousel: NgbCarousel, action: string = this.SWIPE_ACTION.RIGHT){
    carousel.pause();
    if(action == this.SWIPE_ACTION.RIGHT){
      carousel.prev();
    }
    if(action == this.SWIPE_ACTION.LEFT){
      carousel.next();
    }
    carousel.cycle();
  }

  onSlide(slideEvent: NgbSlideEvent) {
    const tileCount = this.calcTileCount();
    if(tileCount != this.tileCount){
      this.tileCount = tileCount;
      this.calcSlides();
    }
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

}
