import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Validation from '@app/_helpers/validation';
import { ApiModule } from '@app/_services';

export enum SubmitState{
  none = 1,
  success = 2,
  failure = 3
}

@Component({
  selector: 'app-user-resetpassword',
  templateUrl: './user-resetpassword.component.html',
  styleUrls: ['./user-resetpassword.component.scss']
})
export class UserResetpasswordComponent implements OnInit {
  submitted = false;
  loading = false;
  SubmitState = SubmitState;
  changed:SubmitState = SubmitState.none;
  token!: string | null;
  changePasswordForm: UntypedFormGroup = this.formBuilder.group({
    password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(40)]],
    repeatpassword: ['', [Validators.required]],
},
{
  validators: [Validation.match('password', 'repeatpassword')]
});
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: ApiModule.UsersService
  ) { }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(40)]],
      repeatpassword: ['', [Validators.required]],
    },
    {
      validators: [Validation.match('password', 'repeatpassword')]
    });
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
      console.log(this.token)
    })
  }
  // convenience getter for easy access to form fields
  get f() { return this.changePasswordForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.changePasswordForm.invalid) {
        return;
    }

    this.userService.changePassword(this.token, this.f['password'].value).subscribe(res =>{
      if(res === true){
        this.changed = SubmitState.success;
      } else {
        this.changed = SubmitState.failure;
      }
    });
  }
}
