import { BrowserModule, HammerModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig } from '@angular/platform-browser';
import { NgModule, Injectable, LOCALE_ID, DEFAULT_CURRENCY_CODE, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { TransferHttpCacheModule } from '@nguniversal/common'; // HERE

import { registerLocaleData } from '@angular/common';
import localeDa from '@angular/common/locales/da';
registerLocaleData(localeDa);

import { environment } from '@environments/environment';
//Routing
import { AppRoutingModule } from '@app/app-routing.module';
//Auth
import { JwtInterceptor, ErrorInterceptor } from '@helpers/index';
// Services
import { ApiModule } from '@services/api.service';
// 3rd party
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import * as Hammer from '@egjs/hammerjs'; //note special import for Angular Universal
//shared
import { SharedModule } from '@shared/shared.module';

//Main Components
import { AppComponent } from '@app/app.component';
import { NavMenuComponent } from '@shared/nav-menu/nav-menu.component';
import { FooterComponent } from '@shared/footer/footer.component';
import { HomeComponent } from '@site/home/home.component';
import { TestimonialsComponent } from '@site/testimonials/testimonials.component';
import { PageNotFoundComponent } from '@site/page-not-found/page-not-found/page-not-found.component';
import { LoadingComponent } from '@shared/loading/loading.component';
import { LoadingService } from '@shared/loading/loading.service';
import { CartService } from '@services/cart.service';
import { CartComponent } from '@shared/cart/cart.component';
import { LoginComponent } from '@user/user-login';
import { RegisterComponent } from '@user/user-register/user-register.component';
import { UserForgotPasswordComponent } from '@user/user-forgot-password/user-forgot-password.component';
import { UserResetpasswordComponent } from '@user/user-resetpassword/user-resetpassword.component';
import { NgxMatomoTrackerModule } from '@ngx-matomo/tracker';
import { NgxMatomoRouterModule } from '@ngx-matomo/router';
import { MyErrorHandler } from './_helpers/error.handler';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  override = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

export function getBaseUrl(): string {
  return environment.apiUrl;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NavMenuComponent,
    FooterComponent,
    HomeComponent,
    TestimonialsComponent,
    PageNotFoundComponent,
    RegisterComponent,
    LoadingComponent,
    CartComponent,
    UserForgotPasswordComponent,
    UserResetpasswordComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'Client'
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    TransferHttpCacheModule,
    RouterModule,
    AppRoutingModule,
    HammerModule,
    NgbModule,
    OverlayModule,
    SharedModule,
    NgxMatomoTrackerModule.forRoot({ trackerUrl: 'https://analytics.socksnjocks.dk', siteId: '1' }),
    NgxMatomoRouterModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'da-DK' },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'DKK'},
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ApiModule.API_BASE_URL, useFactory: getBaseUrl },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig  },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    LoadingService,
    CartService
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    // Use a custom replacer to display function names in the route configs
    // const replacer = (key, value) => (typeof value === 'function') ? value.name : value;

    // console.log('Routes: ', JSON.stringify(router.config, replacer, 2));
  }
}
