<div class="fixed-top header-container">
  <div class="admin-links" *ngIf="isAdmin | async">
    <div class="nav-item dropdown" ngbDropdown >
      <a class="nav-link admin-link" id="dropdownAdminButton" ngbDropdownToggle><i class="fal fa-cog"></i></a>
      <div aria-labelledby="dropdownAdminButton" ngbDropdownMenu>
        <a [routerLink]='["/admin"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-tachometer-fast"></i>Dashboard</a>
        <a [routerLink]='["/admin/kasseapparat"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-cash-register"></i>Kasseapparat</a>
        <a [routerLink]='["/admin/ordrer"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-bags-shopping"></i>Ordrer</a>
        <a [routerLink]='["/admin/lager"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-inventory"></i>Lager</a>
        <a [routerLink]='["/admin/bokse"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-box"></i>Bokse</a>
        <a [routerLink]='["/admin/produkter"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-cube"></i>Produkter</a>
        <a [routerLink]='["/admin/produkt-egenskaber"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-cubes"></i>Produktegenskaber</a>
        <a [routerLink]='["/admin/brugere"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-user"></i>Brugere</a>
        <a [routerLink]='["/admin/jobs"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-clock"></i>Jobs</a>
        <a [routerLink]='["/admin/logs"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-history"></i>Logs</a>
        <a [routerLink]='["/admin/integrationer"]' class="dropdown-item" ngbDropdownItem><i class="fal fa-handshake"></i>Integrationer</a>
      </div>
    </div>
  </div>
  <div class="header-links container-fluid" id="header-links">
    <nav class="nav justify-content-end" id="header-links-nav">
      <a class="navbar-brand" [routerLink]="['/']"><img [src]="logoImg" [srcset]="logoSvg" class="nav-logo" id="nav-logo" /></a>
      <div class="nav-item dropdown" ngbDropdown >
        <a class="nav-link header-link" id="dropdownUserButton" ngbDropdownToggle><i class="fal fa-user-circle me-0"></i></a>
        <div aria-labelledby="dropdownUserButton" ngbDropdownMenu>
          <ng-container *ngIf="!(isAuthorized | async)">
            <button ngbDropdownItem [routerLink]='["/registrer"]'><i class="fal fa-user-plus"></i>Registrér</button>
            <button ngbDropdownItem [routerLink]='["/log-ind"]'><i class="fal fa-sign-in"></i>Log ind</button>
          </ng-container>
          <ng-container *ngIf="(isAuthorized | async)">      
            <a (click)="logout()" class="dropdown-item" ngbDropdownItem><i class="fal fa-sign-out"></i>Log ud</a>
          </ng-container>
        </div>
      </div>
      <div class="nav-item dropdown btn-with-circle" ngbDropdown #scDrop="ngbDropdown" id="minicartDdl">
        <a class="nav-link header-link" id="dropdownShoppingCart" ngbDropdownToggle  role="button">
          <span class="cart-label"><i class="fal fa-cart-plus"></i></span> <span class="cart-qty circle">{{itemCount}}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownShoppingCart" ngbDropdownMenu>          
            <app-cart (click)="$event.stopPropagation(); scDrop.close();"></app-cart>     
            <button *ngIf="items.length > 0" class="btn btn-secondary btb-sm w-100" [routerLink]="'/shop/kurv'" (click)="$event.stopPropagation(); scDrop.close();">Indkøbskurv</button>         
        </div>
      </div>
      <button class="navbar-toggler d-lg-none ddb"
              type="button"
              data-toggle="collapse"
              data-target=".navbar-collapse"
              aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded"
              (click)="toggle()">
        <span class="navbar-toggler-icon"><i class="fal fa-bars"></i></span>
      </button>
    </nav>
  </div>
  <nav class="navbar navbar-expand-lg bg-black top-menu" id="navbar-top">
    <div class="container">
      <div id="nav-desktop" class="navbar-collapse collapse" [ngClass]="{ show: isExpanded }">
        <div class="navbar-nav menu-category w-100 d-flex" id="menu-category">
          <div class="nav-item nav-links-first">
            <a class="nav-link text-white" [routerLink]='["/shop/sokker"]' (click)="isExpanded = false">Sokker</a>
          </div>
          <div class="nav-item">
            <a class="nav-link text-white" [routerLink]='["/shop/underbukser"]' (click)="isExpanded = false">Underbukser</a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</div>