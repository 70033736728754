import { Component, OnInit, Input } from '@angular/core';
import { ApiModule } from '@services/api.service';
import { CartService } from '@services/cart.service';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit {

  @Input() product!: ApiModule.ProductDto;
  @Input() showDesciption: boolean = true;
  image!: string;
  defaultImage = '/assets/logo/1x1.png';
  link: string = '#'
  cartDisabled = true;
  public selectedSize: number = 0;
  constructor(
    private cartService: CartService
  ) 
  {
  }
  ngOnInit(): void {
    
    let path = '/images/box/' + this.product?.id.toString() + '/thumbnail-500-';
    let image = this.product?.image?.name;
    
    if(image){
      this.image = path + image;
    }
    const boxTypeUrl = this.product.isBox ? 
      this.product?.boxType == ApiModule.BoxType.MagnetBoxSocks || this.product.boxType == ApiModule.BoxType.SuitcaseSocks ? 'sokker' : 'underbukser' :
      this.product?.productType == ApiModule.ProductType.Socks ? 'sokker' : 'underbukser';
    this.link = '/shop/' + boxTypeUrl + '/' + this.product?.urlSlug 
  }

  enableCart(size: number){
    this.selectedSize = size;
    this.cartDisabled = false;
  }
  get btnClass(){
    if(this.selectedSize != 0){
      return 'btn btn-success w-100';
    }
    return 'btn btn-outline-secondary w-100'
  }

  addToCart(){
    this.product.size = this.product?.sizes?.find(size => { return size.id === this.selectedSize})
    this.cartService.addToCart(this.product);
  }
}
