import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@site/home/home.component';
import { LoginComponent } from '@user/user-login';
import { RegisterComponent } from '@user/user-register/user-register.component';
import { UserForgotPasswordComponent } from '@user/user-forgot-password/user-forgot-password.component';
import { UserResetpasswordComponent } from '@user/user-resetpassword/user-resetpassword.component';
import { PageNotFoundComponent } from '@site/page-not-found/page-not-found/page-not-found.component';

import { SelectivePreloadingStrategyService } from '@app/selective-preloading-strategy.service';
import { AuthGuard } from '@helpers/auth.guard';
import { UserRole } from '@models/userrole';


const appRoutes: Routes = [
    {
        path: '',
        component: HomeComponent
    },
    {
      path: '',
      loadChildren: () => import('./site/site.module').then(m => m.SiteModule),
      canLoad: [AuthGuard]
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canLoad: [AuthGuard],
        data: { roles: [UserRole.Admin] }
    },
    {
      path: 'mine-sider',
      loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      canLoad: [AuthGuard]
    },
    {
      path: 'shop',
      loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    },
    {
        path: 'log-ind',
        component: LoginComponent
    },
    {
      path: 'registrer',
      component: RegisterComponent
    },
    {
      path: 'glemt-password',
      component: UserForgotPasswordComponent
    },
    {
      path: 'skift-password/:token/:email',
      component: UserResetpasswordComponent
    },
    // otherwise redirect to page not found
    { path: '**', component: PageNotFoundComponent }
];

@NgModule({
    imports: [
RouterModule.forRoot(
          appRoutes,
          {
            enableTracing: false, // <-- debugging purposes only
            preloadingStrategy: SelectivePreloadingStrategyService,
            scrollPositionRestoration: 'enabled'
          }
        )
      ],
    exports: [RouterModule]
  })
export class AppRoutingModule { }
