<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3 mt-5">
            <div class="card">
                <h4 class="card-header">Nulstil password</h4>
                <div class="card-body" *ngIf="done">
                    <p>Hvis en bruger med den angivne email findes, vil du modtage en mail med et link hvor du kan nulstille dit password.</p>
                </div>                
                <div class="card-body" *ngIf="!done">
                    <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label class="visually-hidden" for="inlineFormInputGroupUsername">Email</label>
                            <div class="input-group">
                                <div class="input-group-text"><i class="fal fa-at me-0"></i></div>
                                <input type="text" class="form-control" id="inlineFormInputGroupUsername" placeholder="Email" formControlName="username" [ngClass]="{ 'is-invalid': submitted && f['username'].errors }">
                                <div *ngIf="submitted && f['username'].errors" class="invalid-feedback">
                                    <div *ngIf="f['username'].errors['required']">Email skal udfyldes</div>
                                    <div *ngIf="f['username'].errors['email']">Email skal være en valid email adress</div>
                                    <div *ngIf="f['username'].errors['maxlength']">Email kan højst være 255 karakterer lang</div>
                                </div>
                            </div>
                        </div>
                        <button [disabled]="loading" class="btn btn-primary float-end mt-4">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Nulstil password
                        </button>
                        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
                    </form>
                </div>
            </div>
        </div>   
    </div>
</div>
         