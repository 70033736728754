import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

//Shared
import { ProductTileComponent } from '@shared/product-tile/product-tile.component';
import { ItemSliderComponent } from '@shared/item-slider/item-slider.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { TranslateStatusPipe } from '@app/_pipes/translate-status.pipe';

@NgModule({
  declarations: [
    ProductTileComponent,
    ItemSliderComponent,
    TranslateStatusPipe
  ],
  imports: [
    NgbModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    LazyLoadImageModule
  ],
  exports:[
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    LazyLoadImageModule,
    ProductTileComponent,
    ItemSliderComponent,
    TranslateStatusPipe
  ],
})
export class SharedModule { }
