<div class="container">
  <div class="row mt-4 justify-content-md-center">
    <div class="col-md-8 text-center">
      <h2 [routerLink]='["/shop/sokker"]'>Sokker</h2>
      <p class="lead mb-1">Er du til klassiske eller sjove sokker?</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-item-slider boxType="sokker"></app-item-slider>
    </div>
  </div>
  <hr class="featurette-divider">
  <div class="row justify-content-md-center">
    <div class="col-md-8 text-center">
      <h2 [routerLink]='["/shop/underbukser"]'>Underbukser</h2>
      <p class="lead mb-1">Klassiske boxershorts og seje retro tighty-whities.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <app-item-slider boxType="underbukser"></app-item-slider>
    </div>
  </div>
  <hr class="featurette-divider">
  <div class="row">
    <div class="col-12 text-center">
      <h2>Tilfredse kunder</h2>
      <h5 class="text-muted">Glade kunder er en vigtig del af vores forretning så tag endelig fat i os med spørgsmål og kommentarer.</h5>
    </div>
  </div>
  <app-testimonials></app-testimonials>
</div>
