import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { UserRole } from '@models/userrole';
import { Cart } from '@models/cart';
import { AuthenticationService } from '@services/authentication.service';
import { CartService } from '@services/cart.service'
import { ApiModule } from '@services/api.service'

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  logoImg = 'assets/logo/logo.png';
  logoSvg = 'assets/logo/logo.svg 1x';
  isAdminSubject = new BehaviorSubject<boolean>(false);
  isAdmin = this.isAdminSubject.asObservable();
  isAuthorizedSubject = new BehaviorSubject<boolean>(false);
  isAuthorized = this.isAuthorizedSubject.asObservable();

  items : ApiModule.ProductDto[];
  itemCount: number;
  private cartSubscription! : Subscription;
  private authSubscription! : Subscription;

  constructor(private authenticationService: AuthenticationService, private cartService: CartService){
    this.items = this.cartService.getItems();
    this.itemCount = this.cartService.count;
   }

  ngOnInit(): void {
      this.authSubscription = this.authenticationService.userSubject.subscribe(res =>{
        this.isAdminSubject.next(res.role == UserRole.Admin);
        this.isAuthorizedSubject.next(res.username != null);
      })
      this.cartSubscription = this.cartService.cart.subscribe((cart : Cart) =>{
        this.items = cart.items;
        this.itemCount = this.cartService.count;
      })
  }

  ngOnDestroy(): void {
    if(this.authSubscription){
      this.authSubscription.unsubscribe();
    }
    if(this.cartSubscription)
    this.cartSubscription.unsubscribe();
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout(){
    this.authenticationService.logout();
  }

}
